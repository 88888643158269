import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Seo from '../components/shared/seo'
import AuthorCard from '../components/blog/authorCard'
import { Breadcrumbs } from '../components/blog/breadcrumbs'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getImage } from 'gatsby-plugin-image'
import BlogCard from '../components/blog/blogCard'
// import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import ShareButtons from '../components/blog/sharebuttons'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import ContentTable from '../components/blog/contentTable'
const Blog = styled.div`
  display: flex;
  flex-direction: column;
  color: #111111;
  font-family: Work Sans;
  font-style: normal;
  h1 {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .vector {
    width: 100px;
  }

  .body {
    a {
      cursor: pointer;
      color: #0645ad;
      :hover {
        text-decoration: underline;
      }
    }
  }
`
const Div = styled.div`
  text-align: left;
  margin-right: 5%;
  /* margin-left: auto; */
  margin: auto;
  width: 80%;
  transition: width 1s;
  @media (max-width: 980px) {
    width: 90%;
  }
  @media (max-width: 375px) {
    width: 92%;
  }

  /* p {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  } */
`
const DivRelated = styled.div`
  text-align: left;
  margin: auto;
  width: 100%;
`

const Hero = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  padding: 1%;
  img {
    width: 70%;
    max-height: 480px;
    max-width: 450px;
    height: auto;
    margin: auto;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
  gap: 5% 5%;

  a {
    text-decoration: none;
  }
  /* align-items: center;
  justify-content: center; */
`

const RelatedBlogPosts = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #111111;
  margin: 50px 0;
`

const Related = styled.div`
  background: #fbfbfb;
  border-top: 1px solid #e1e1e1;
  width: 100%;
`

const PublishDate = styled.div`
  margin: auto;
  width: 80%;
  text-align: center;
`

const PublishAuthor = styled.div`
  margin: auto;
  width: 80%;
  text-align: center;
  margin-bottom: 32px;
`

const CardPreview = styled.div`
  width: 90%;
  height: 1000px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation-name: breath-animation;
  animation-duration: 5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  margin: 32px auto;
  h2 {
    width: 100%;
    text-align: center;
  }

  @keyframes breath-animation {
    0% {
      width: 580px;
    }

    25% {
      width: 580px;
    }

    75% {
      width: 280px;
    }

    100% {
      width: 280px;
    }
  }
`

const BlogPost = ({ data, location }) => {
  // console.log(data)
  let { post } = data
  let { prev } = data
  let { next } = data
  let { related1 } = data
  let { related2 } = data
  const { site } = data
  // console.log(post?.nodes[0].data)
  post = post?.nodes[0].data
  prev = prev?.nodes[0]
  next = next?.nodes[0]
  related1 = related1?.nodes[0]
  related2 = related2?.nodes[0]
  // console.log("related", related1)

  if (!post) post = data.prismicPdfproPost.data

  const url = site?.siteMetadata.siteUrl + location.pathname
  const description = post.description ? post.description : 'Blog post'

  const [tableContent, setTableContent] = useState()
  const [tableContentType, setTableContentType] = useState()

  function offsetAnchor() {
    const hash = decodeURI(window.location.hash.replace('#', ''))
    const target = document.getElementById(hash)
    console.log(hash, ':', target)

    if (target != null) {
      if (location.hash.length != 0) {
        window.scrollTo(window.scrollX, target.offsetTop - 100)
      }
    }
  }

  useEffect(() => {
    /// Popup logic
    const aElements = document.getElementsByTagName('a')
    const bEl = document.getElementById('#id')
    const content = []
    const contentType = []

    /// Handling scrolling difference on page landing,
    window.setTimeout(offsetAnchor, 1)

    for (let i = 0, l = aElements.length; i < l; i++) {
      const el = aElements[i]

      if (el.href.includes(':NoFollow')) {
        el.href = el.href.split(':NoFollow')[0]
        el.rel = 'nofollow'
      }
    }

    for (let i = 0, l = bEl.children.length; i < l; i++) {
      if (bEl.children[i].nodeName == 'H2' || bEl.children[i].nodeName == 'H3') {
        bEl.children[i].id = bEl.children[i].textContent
        content.push(bEl.children[i].textContent)
        contentType.push(bEl.children[i].nodeName)
      }
    }

    setTableContent(() => content)
    setTableContentType(() => contentType)
  }, [])
  console.log('data: ', data)

  return (
    <>
      <Blog>
        <Seo title={post?.title} schemaMarkup={post?.schema} description={post?.description} />
        <Div>
          <Breadcrumbs
            currentPath={['/'].concat(location.pathname.split('/').filter((name) => name !== ''))}
            customUrl={post?.title}
          />
        </Div>
        <Div>
          {data?.prismicPdfproPost && (
            <CardPreview>
              <h2>Card Preview: </h2>
              <BlogCard
                post={{ data: post }}
                author={post.author.document.data.name}
                visible={'flex'}
                margin={'auto'}
              />
            </CardPreview>
          )}

          <Hero image={getImage(post?.hero)} alt={'hero'} />
          <PublishDate>Published on: {post?.publish_date}</PublishDate>
          <PublishAuthor>by {post.author.document?.data.name}</PublishAuthor>

          <ShareButtons url={url} title={post.title} description={description} />
          <ContentTable content={tableContent} contentType={tableContentType} />

          <Div id="#id" className="body" dangerouslySetInnerHTML={{ __html: post?.body.html }} />
          <ShareButtons url={url} title={post.title} description={description} />
        </Div>
      </Blog>
      {post?.author.document?.data.name && (
        <AuthorCard posts={post?.author} author={post?.author.document.data.name} bio={true} />
      )}
      <Related>
        <RelatedBlogPosts>Related Blog Posts </RelatedBlogPosts>

        <DivRelated>
          <Flex>
            {prev && !related1 && (
              <BlogCard post={prev} author={prev.data.author.document.data.name} visible={'flex'} />
            )}
            {next && !related2 && (
              <BlogCard post={next} author={next.data.author.document.data.name} visible={'flex'} />
            )}
            {related1 && <BlogCard post={related1} author={related1.data.author.document.data.name} visible={'flex'} />}
            {related2 && <BlogCard post={related2} author={related2.data.author.document.data.name} visible={'flex'} />}
          </Flex>
        </DivRelated>
      </Related>
    </>
  )
}

export const query = graphql`
  query BlogQuery($uid: String!, $prev: String!, $next: String!, $related1: String!, $related2: String!) {
    post: allPrismicPdfproPost(filter: { uid: { eq: $uid } }) {
      nodes {
        _previewable
        data {
          body {
            html
          }
          schema
          title
          fit_image
          category
          description
          publish_date(formatString: "MMMM D, Y")
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  facebook
                  image {
                    gatsbyImageData
                  }
                  linkedin
                  name
                  title
                  twitter
                  shortbio {
                    text
                  }
                }
              }
            }
          }
          hero {
            gatsbyImageData
          }
          title
        }
        uid
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

    prev: allPrismicPdfproPost(filter: { uid: { eq: $prev } }) {
      nodes {
        _previewable
        data {
          url
          fit_image
          title
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }

    next: allPrismicPdfproPost(filter: { uid: { eq: $next } }) {
      nodes {
        _previewable
        data {
          url
          fit_image
          title
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }

    related1: allPrismicPdfproPost(filter: { uid: { eq: $related1 } }) {
      nodes {
        _previewable
        data {
          url
          fit_image
          title
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }

    related2: allPrismicPdfproPost(filter: { uid: { eq: $related2 } }) {
      nodes {
        _previewable
        data {
          url
          fit_image
          title
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`

export default withPrismicPreview(BlogPost)
