import React from "react";
import { SocialIcon } from "react-social-icons";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";

import styled from "styled-components";

const ShareStyle = styled.div`
  display: flex;
  justify-content: center;
  .react-share__ShareButton {
    margin: 2% 8px;
  }

  @media (max-width: 800px) {
    .react-share__ShareButton {
      margin: 0 8px;
      margin-top: 24px;
    }
  }
`;

const ShareButtons = ({ url, title, description }) => {
  return (
    <ShareStyle>
      <FacebookShareButton url={url} quote={description}>
        <SocialIcon network="facebook" />
      </FacebookShareButton>

      <LinkedinShareButton url={url} title={title} summary={description}>
        <SocialIcon network="linkedin" />
      </LinkedinShareButton>

      <TwitterShareButton url={url} title={description}>
        <SocialIcon network="twitter" />
      </TwitterShareButton>

      <WhatsappShareButton url={url} title={description}>
        <SocialIcon network="whatsapp" />
      </WhatsappShareButton>

      <EmailShareButton
        subject={title}
        body={`${description}, check full guide here: ${url}`}
      >
        <SocialIcon network="email" />
      </EmailShareButton>
    </ShareStyle>
  );
};

export default ShareButtons;
