import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getImage } from 'gatsby-plugin-image'
import Linkedin from '../../icons/blog/Linkedin-13.svg'
import Twitter from '../../icons/blog/Twitter-19.svg'
import Facebook from '../../icons/blog/Facebook.svg'

const Article = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60%;
  transition: width 1s;
  @media (max-width: 980px) {
    width: 75%;
  }
  @media (max-width: 375px) {
    width: 80%;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  margin: auto;
  border-top: 1px solid #e1e1e1;

  padding-top: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
`

const Picture = styled(GatsbyImage)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 100%;
  margin: 16px;
  min-width: 135px;
  width: 135px;
  height: 135px;
  align-self: flex-start;
  @media (max-width: 600px) {
    margin-left: 0;
    margin-bottom: 32px;
  }
  img {
    z-index: 1;
    width: 105%;
    height: 100%;
  }
`

const Author = styled(Link)`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
  text-decoration: none;
  width: 100%;
`

const AuthorTitle = styled(Link)`
  width: 100%;
  flex-basis: 100%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin-top: 4px;
  margin-bottom: 16px;
`

const Bio = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 16px;

  color: #4f4f4f;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`
const Vector = styled(Link)`
  width: 32px;
  margin-right: 8px;
`

const Info = styled.div`
  margin-left: 45px;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`

function AuthorCard({ posts, author, bio }) {
  console.log('text', posts.document.data.linkedin)
  const linked = posts.document.data.linkedin
  console.log(typeof linked)
  return (
    <Article>
      <Picture image={getImage(posts.document.data.image)} alt="thumbnailA"></Picture>

      <Info>
        <Author to={`/blog/${posts.document.data.name}`}>{author}</Author>

        <AuthorTitle>{posts.document.data.title}</AuthorTitle>

        {bio && <Bio>{posts.document.data.shortbio.text} </Bio>}

        <Row>
          {posts.document.data.linkedin && (
            <Vector to={posts.document.data.linkedin}>
              <Linkedin />
            </Vector>
          )}

          {posts.document.data.twitter && (
            <Vector to={posts.document.data.twitter}>
              <Twitter />
            </Vector>
          )}
          {posts.document.data.facebook && (
            <Vector to={posts.document.data.facebook}>
              <Facebook />
            </Vector>
          )}
        </Row>
      </Info>
    </Article>
  )
}

export default AuthorCard
