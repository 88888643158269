import React from "react";
import styled from "styled-components";
import Logo from "../../icons/shared/pdf_logo.svg";
import { createGlobalStyle } from "styled-components";
import { Link } from "gatsby";

//Error boundary has to be class component - still can't achieve that with functional components
//Later you can add custom UI

const GlobalStyle = createGlobalStyle`
  html,body {
    margin: 0;
    padding: 0;
    //width has to be 100% and not 100vw because if it's 100vw then you will get horizontal scroll when you have vertical scroll
    //because vw will include scrollbar measurements
    width:100%;
    box-sizing:border-box;

    @media(max-width:330px){
      overflow-x:hidden;
    }
  }

  *{
    font-family: Roboto, Sans-Serif;
    //this could be problematic with some desings but it shouldn't be if you do it properly
    box-sizing:border-box;
  }

`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100vh;
  width: 100%;

  .logo-icon {
    transform: scale(2);
    margin-bottom: 48px;
    fill: black;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <GlobalStyle />
          <Container>
            <Content>
              <Link to="/">
                <Logo className="logo-icon" />
              </Link>
              <h1>Ooops! Something went wrong.</h1>
              <p>
                Contact{" "}
                <a href="https://pdfreader.zendesk.com/hc/en-us/requests/new">
                  support
                </a>{" "}
                so we can get the error fixed.
              </p>
              <p>You may also try to refresh the page or try again later.</p>
            </Content>
          </Container>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
