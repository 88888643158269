import { create } from "zustand";

const pathMapping = {
  "pdf-reader": "pdf-reader",
  "pdf-reader-maintenance": "pdf-reader-maintenance",
  "pdf-pro": "pdf-pro",
  "pdf-pro-upgrade": "pdf-pro-upgrade",
  "pdf-pro-maintenance": "pdf-pro-maintenance",
};

const useCheckout = create((set) => ({
  RealPath: "pdf-reader",
  ProductPath: "pdf-reader",
  AddOn: true,
  Amount: 1,
  email: "",
  fname: "",
  lname: "",
  phoneNumber: "",
  cardNumber: "",
  emailSubscription: false,
  expirationDate: "",
  securityCode: "",
  zipCode: "",
  paymentGateway: "",

  setPath: (path) => {
    let realPath = pathMapping[path];

    set((state) => {
      // if (state.AddOn && !path.includes("-eds")) {
      //   realPath = realPath + "-eds";
      // }
      return { ...state, ProductPath: path, RealPath: realPath };
    });
  },
  setEmail: (email) => set({ email: email }),
  setFname: (fname) => set({ fname: fname }),
  setLname: (lname) => set({ lname: lname }),
  setPhoneNumber: (phoneNumber) => set({ phoneNumber: phoneNumber }),
  setCardNumber: (cardNumber) => set({ cardNumber: cardNumber }),
  setExpirationDate: (expirationDate) =>
    set({ expirationDate: expirationDate }),
  setSecurityCode: (securityCode) => set({ securityCode: securityCode }),
  setZipCode: (zipCode) => set({ zipCode: zipCode }),
  setEmailSubscription: (emailSubscription) =>
    set({ emailSubscription: emailSubscription }),
  setPaymentGateway: (paymentGateway) =>
    set({ paymentGateway: paymentGateway }),
  setAmount: (amount) => {
    set((state) => {
      console.log("Current amount: ", amount);
      return { ...state, Amount: amount };
    });
  },

  setAddOn: (AddOn) => {
    set((state) => {
      let realPath = state.ProductPath;

      // if (AddOn) {
      //   realPath = realPath + "-eds";
      // }

      return { ...state, RealPath: realPath, AddOn: AddOn };
    });
  },
  ProductDiscount: null,
  OcrDiscount: null,
  setProductDiscount: (discount) => set({ ProductDiscount: discount }),
  setOcrDiscount: (discount) => set({ OcrDiscount: discount }),
  ProductPathDiscount: "",
  LocationPathDiscount: "",
  setLocationPathDiscount: (discount) =>
    set({ LocationPathDiscount: discount }),
  setProductPathDiscount: (discount) => set({ ProductPathDiscount: discount }),
  lockLanguage: false,
  setLockLanguage: (lock) => set({ lockLanguage: lock }),
}));

export default useCheckout;
