import React from 'react'
import styled from 'styled-components'
import { useEffect } from 'react'
// const Table = styled.div`
//   display: flex;
//   flex-direction: column;

//   position: fixed;

//   top: 100px;
//   z-index: 99999;
//   width: 22%;
//   margin: 0 2%;
// `

// const Content = styled.a`
//   border-top-right-radius: 8px;
//   border-bottom-right-radius: 8px;

//   color: #111111;
//   text-decoration: none;
//   text-decoration: bold;
//   font-weight: 700;
//   font-size: 14px;
//   border-left: 6px solid #e44331;
//   padding-left: 8px;
//   ${props =>
//     props.type == "H2" ? `padding-left: 8px;` : `padding-left: 40px;`}
//   margin: 4px 0;
// `

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 16px auto;
  box-shadow: 0px 0px 20px -8px rgba(0, 0, 0, 0.1);
  padding: 24px;
  border-radius: 8px;
`

const Content = styled.a`
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #111111;
  text-decoration: none;
  text-decoration: bold;
  font-weight: 700;
  font-size: 14px;
  padding-left: 8px;
  ${(props) => (props.type == 'H2' ? `padding-left: 8px;` : `padding-left: 40px;`)}
  margin: 4px 0;
  :hover {
    color: #e44331;
  }
`

function ContentTable({ content, contentType }) {
  useEffect(() => {
    let parent = document.getElementById('sticky').parentElement

    while (parent) {
      const hasOverflow = getComputedStyle(parent).overflow
      if (hasOverflow !== 'visible') {
        console.log(hasOverflow, parent)
      }
      parent = parent.parentElement
    }
  }, [])
  let first = 0
  let second = 0
  return (
    <Table id="sticky">
      <h4 style={{ width: '100%', textAlign: 'center' }}>Table of Contents:</h4>
      {content?.map((a, i) => {
        if (contentType[i] == 'H3') {
          second += 1
        } else {
          first += 1
          second = 0
        }
        return (
          <Content
            type={contentType[i]}
            href={`#${a}`}
            onClick={() => {
              const title = document.getElementById(a)
              window.setTimeout(() => window.scrollTo(window.scrollX, title.offsetTop - 100), 1)
            }}
          >
            {`${contentType[i] == 'H2' ? first + '. ' + a : first + '.' + second + '. ' + a}`}
          </Content>
        )
      })}
    </Table>
  )
  //   return <div>ahaaa</div>
}

export default ContentTable
