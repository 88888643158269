import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import Logo from "../../icons/shared/pdf_logo_dark.svg";
import { useContext } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";
import Win7 from "../../icons/shared/win7.svg";
import Win10 from "../../icons/shared/win10.svg";
import Win11 from "../../icons/shared/win11.svg";
import { StaticImage } from "gatsby-plugin-image";

const FooterComponent = styled.footer`
  display: flex;
  justify-content: center;
  height: 160px;
  width: 100%;
  background: #222222;
  position: relative;
  margin: 0px;
  padding: 0px;
  z-index: ${({ navAcitve }) => (navAcitve ? "-2" : "10")};
  @media (max-width: 1060px) {
    height: max-content;
    padding: 36px 0px;
    align-items: center;
  }
  @media (min-width: 1060px) {
    .middle {
      margin: 0 32px;
    }
  }

  .logo {
    height: 31px;
    width: 164px;
    //you removed fill on the svg so you can use the same logo for header&footer
    fill: #ffffff;
  }

  .company-info {
    align-items: flex-start;
  }
`;

const PositionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  width: 90%;
  height: 100%;

  @media (max-width: 1060px) {
    flex-direction: column;
    height: max-content;
    gap: 30px;
  }

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const Boxes = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.673077px;
  color: #ffffff;
  width: 100%;
  text-decoration: none;
  .PDFA {
    margin-left: 8px;
    width: max-content;
  }
  @media (max-width: 1060px) {
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    line-height: 18px;
    .PDFA {
      margin-top: 12px;
    }
  }

  a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.673077px;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    width: 100%;
    width: max-content;

    @media (max-width: 1060px) {
      font-size: 14px;
      line-height: 40px;
      color: #ffffff;
      text-align: left;
    }

    &:hover {
      font-weight: bold;
    }
  }

  //fix for width change on hover
  a::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  span {
    margin: 0px 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;

    @media (max-width: 1200px) {
      margin: 0px 5px;
    }
    @media (max-width: 1060px) {
      display: none;
    }
  }
`;

const BoxesHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  @media (max-width: 1100px) {
    align-items: flex-start;
  }
`;

const WinIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;

  svg {
    width: 30px;
    height: 30px;
  }

  div {
    color: ${(props) => (props.color ? `${props.color}` : "gray")};
    text-align: center;
    font-weight: 100;
    font-size: 12.96px;
    line-height: 15px;
    margin-top: 2px;

    @media (max-width: 768px) {
      font-size: 9.3312px;
      line-height: 11px;
    }
  }
`;

function Footer() {
  const { isMobileNavOpen } = useContext(GlobalContext);
  return (
    <FooterComponent navAcitve={isMobileNavOpen}>
      <PositionContainer>
        <BoxesHolder className="company-info">
          <Boxes>
            <Logo className="logo" />
          </Boxes>
          <Boxes>
            PDF Pro Software Inc.
            <br />
            1383 W. 8th Avenue <br />
            Vancouver, B.C. V6H 3W4
          </Boxes>
        </BoxesHolder>
        <BoxesHolder className="middle">
          <Boxes>
            <Link to={`/uninstall/`}>Uninstall</Link> <span>|</span>
            <Link to={`/privacy/`}>Privacy Policy</Link>
            <span>|</span>
            <Link to={`/cookies/`}>Cookie Policy</Link>
            <span>|</span>
            <Link to={`/eula/`}>EULA</Link>
          </Boxes>
          <Boxes>
            <Link to={`https://softwaremarketinglimited.zendesk.com/hc/en-us`}>
              Knowledge Base
            </Link>
            <span>|</span>
            <Link to={`/terms-and-conditions/`}>Terms & Conditions</Link>{" "}
            <span>|</span>
            <Link to={`https://pdfreader.zendesk.com/hc/en-us/requests/new`}>
              Contact us
            </Link>{" "}
          </Boxes>
        </BoxesHolder>
        <BoxesHolder className="last">
          <Boxes>
            © 2017-{new Date().getFullYear()}, PDF Pro Software Inc.
            <br /> All rights reserved.
          </Boxes>
          <Boxes style={{ alignItems: "flex-start" }}>
            Member of:
            <Link
              className="PDFA"
              to="https://www.pdfa.org/member/pdf-pro-software-inc"
              style={{ marginTop: "bottom" }}
            >
              <StaticImage
                src="../../images/PDFA-white.png"
                quality={95}
                width={64}
              />
            </Link>
          </Boxes>
        </BoxesHolder>
      </PositionContainer>
    </FooterComponent>
  );
}

export default Footer;
