import React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";
import { linkResolver } from "./src/utils/linkResolver";
import BlogTemplate from "./src/templates/blogpost.js";

import ErrorBoundary from "./src/components/shared/errorBoundary";
import Layout from "./src/components/shared/layout";
import GlobalContextProvider from "./src/Contexts/GlobalContext";
import { Helmet } from "react-helmet";
export const wrapRootElement = ({ element }) => {
  return (
    <GlobalContextProvider>
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: process.env.PRISMIC_REPOSITORY,
            linkResolver,
            componentResolver: componentResolverFromMap({
              pdfpro_post: BlogTemplate,
            }),
          },
        ]}
      >
        {element}
      </PrismicPreviewProvider>
    </GlobalContextProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  console.log(props);
  return (
    <ErrorBoundary>
      <Helmet>
        <script type="text/javascript">var baseurl = "getreader.com";</script>
        <script
          async
          type="text/javascript"
          src="https://track.pdfpro10.com/static/tracking.js"
        ></script>
      </Helmet>
      <Layout wrapLocation={props.location}>{element}</Layout>
    </ErrorBoundary>
  );
};
