import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useState } from 'react'
import { useEffect } from 'react'

function Seo({ description, lang, meta, title, schemaMarkup, special }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = special ? null : site.siteMetadata?.title

  const capitalizeFirstLetter = (text) => {
    return text[0].toUpperCase() + text.slice(1, text.length)
  }

  const [scripts, setScripts] = useState(null)
  useEffect(() => {
    const parser = new DOMParser()
    const schemaArray = parser.parseFromString(schemaMarkup, 'text/html').querySelectorAll('script')
    // console.log(schemaArray[0])
    const scr = []
    schemaArray.forEach((e, i) => (scr[i] = <script type="application/ld+json">{`${e.innerHTML}`}</script>))
    setScripts(scr)
  }, [schemaMarkup])

  // console.log(scripts)
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={capitalizeFirstLetter(title)}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {scripts}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
