import React, { useState, useEffect } from "react";
import { createContext } from "react";

//creating context
export const GlobalContext = createContext({});

//creating component that will be wrapping the root element to we can use global context on all pages and components
const GlobalContextProvider = ({ children }) => {
  const [urlParameter, setUrlParameter] = useState("asa");
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [macOpen, setMacOpen] = useState(false);
  const [isMac, setIsMac] = useState(null);

  useEffect(() => {
    var isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    var isiPhone = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // Usage
    if (isMac) {
      setIsMac(true);
    }

    if (isiPhone) {
      setIsMac(true);
    }
  }, [macOpen]);

  return (
    <GlobalContext.Provider
      value={{
        urlParameter,
        setUrlParameter,
        isMobileNavOpen,
        setIsMobileNavOpen,
        macOpen,
        setMacOpen,
        isMac,
        setIsMac,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
