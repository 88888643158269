import React from "react";
import styled from "styled-components";
import Logo from "../../icons/shared/pdf_logo.svg";
import { Link, navigate } from "gatsby";
import Hamburger from "../../icons/shared/hamburger_menu.svg";
import Close from "../../icons/shared/close_icon.svg";
import { useState, useEffect } from "react";
import { globalHistory } from "@reach/router";
import { useContext } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";

const BackgroundFiller = styled.div`
  position: relative;
  margin: 0 auto;
  margin-left: 0;
  height: 55px;
`;
const HeaderS = styled.div`
  width: 80%;
  min-height: 55px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index: 9;
  max-width: 1100px;
  ${(props) =>
    props.maxW == "lp1" ? `max-width: 1100px;` : `max-width: 1100px;`}
  margin: auto;
  svg {
    cursor: pointer;
    max-width: 1690px;
    max-height: 320px;
  }
  @media (max-width: 768px) {
    svg {
      position: absolute;
      margin-top: 14px;
      max-width: 169px;
      max-height: 32px;
    }
    align-items: flex-start;
  }
`;

const HeaderLimit = styled.div`
  width: 100%;
  background: white;
  position: fixed;
  ${(props) => (props.closedSticky == false ? `top: 41px;` : `top: 0;`)}
  z-index: 1000;
  border-bottom: 1px solid #e1e1e1;
`;

const NavBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-transform: Uppercase;

  a {
    font-size: 12px;
    line-height: 28px;
    text-decoration: none;
    color: #000000;
    margin-left: 5%;
    display: flex;
    text-transform: Uppercase;

    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }
  width: 100%;

  @media (max-width: 768px) {
    display: none;
    flex-direction: column;
    padding: 70px 0;
  }
`;

const NavBarMobile = styled.div`
  display: none;
  justify-content: flex-end;
  align-items: center;
  text-transform: capitalize;
  a {
    font-size: 12px;
    line-height: 28px;
    text-decoration: none;
    color: #000000;
    margin-left: 5%;
    display: flex;
    text-transform: Uppercase;

    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: ${(props) => (props.open ? `32px 0;` : `0`)};
  }
`;

const Bread = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  svg {
    margin-top: 14px;
  }
  @media (max-width: 768px) {
    margin-top: 21px;
    display: flex;
  }
`;

const Download = styled.div`
  border: 1px solid rgb(219, 70, 42);
  padding: 0 16px;
  font-size: 12px;
  border-radius: 4px;
  margin-left: 5%;
  color: rgb(219, 70, 42);
  cursor: pointer;
  :hover {
    background: rgb(219, 70, 42);
    color: white;
  }

  @media (max-width: 768px) {
    margin-top: 24px;
  }
`;

function HeaderLp({ wrapLocation }) {
  const [open, setOpen] = useState(false);
  const [param, setParam] = useState("");

  /// Detects URL change
  const [loc, setLoc] = useState(null);
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action == "PUSH" || action == "REPLACE" || action == "POP")
        setLoc((loc) => (loc = !loc));
    });
  }, [setLoc]);

  useEffect(() => {
    setParam(window.location.search);
  }, [loc]);
  console.log(wrapLocation.pathname.split("/")[1]);
  const realRoute = wrapLocation?.pathname.split("/")[1];
  const route = realRoute
    ? realRoute.includes("lp")
      ? `/${realRoute}`
      : ""
    : "";

  const { urlParameter, isMac, setMacOpen } = useContext(GlobalContext);
  const queryString =
    typeof window !== "undefined" ? window.location.search : "";
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  function addParametersToUrl() {
    if (urlParameter !== "" && queryString.length === 0) {
      window.history.pushState(
        { path: url + "?" + urlParameter },
        "",
        url + "?" + urlParameter
      );
    }
  }
  //
  return (
    <BackgroundFiller>
      <HeaderLimit>
        <HeaderS>
          <Logo
            onClick={() => {
              navigate(`${route}/${param}`);
            }}
          />

          <NavBar>
            <Link to={`${route}/about/${param}`}>About us</Link>
            <Link to={`${route}/faq/${param}`}>Faq</Link>
            <Link to="https://pdfreader.zendesk.com/hc/en-us/requests/new">
              Support
            </Link>
            <Link
              className="tracker"
              to={`https://track.pdfpro10.com/product/normal/pdfreader/trial`}
            >
              {!isMac ? (
                <Download
                  onClick={() => {
                    addParametersToUrl();
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "DownloadClick",
                      category: "download",
                      action: "click",
                      label: "download_trial",
                    });
                  }}
                >
                  DOWNLOAD
                </Download>
              ) : (
                <Download
                  onClick={() => {
                    addParametersToUrl();
                    setMacOpen(true);
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "DownloadClick",
                      category: "download",
                      action: "click",
                      label: "download_trial",
                    });
                  }}
                >
                  DOWNLOAD
                </Download>
              )}
            </Link>
          </NavBar>
          <NavBarMobile open={open}>
            {open && (
              <>
                <Link to={`${route}/about/${param}`}>About us</Link>
                <Link to={`${route}/faq/${param}`}>FAQ</Link>
                <Link to="https://pdfreader.zendesk.com/hc/en-us/requests/new">
                  Support
                </Link>
                {!isMac ? (
                  <Link
                    to={`https://track.pdfpro10.com/product/normal/pdfreader/trial`}
                  >
                    <Download
                      onClick={() => {
                        addParametersToUrl();
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                          event: "DownloadClick",
                          category: "download",
                          action: "click",
                          label: "download_trial",
                        });
                      }}
                    >
                      DOWNLOAD
                    </Download>{" "}
                  </Link>
                ) : (
                  <Download
                    onClick={() => {
                      addParametersToUrl();
                      setMacOpen(true);
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "DownloadClick",
                        category: "download",
                        action: "click",
                        label: "download_trial",
                      });
                    }}
                  >
                    DOWNLOAD
                  </Download>
                )}
              </>
            )}
          </NavBarMobile>
          <Bread>
            {!open && (
              <Hamburger onClick={() => setOpen((open) => (open = !open))} />
            )}
            {open && (
              <Close onClick={() => setOpen((open) => (open = !open))} />
            )}
          </Bread>
        </HeaderS>
      </HeaderLimit>
    </BackgroundFiller>
  );
}

export default HeaderLp;
