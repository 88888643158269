import React from "react";
import styled from "styled-components";
import Logo from "../../icons/shared/pdf_logo.svg";
import { Link, navigate } from "gatsby";
import Hamburger from "../../icons/shared/hamburger_menu.svg";
import Close from "../../icons/shared/close_icon.svg";
import { useState, useEffect } from "react";
import { globalHistory } from "@reach/router";
import { useContext } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";
import CountryDropdown from "../Checkout/CountryDropdown/CountryDropdown";
const BackgroundFiller = styled.div`
  position: relative;
  margin: 0 auto;
  margin-left: 0;
  height: 55px;
`;
const HeaderS = styled.div`
  width: 80%;
  min-height: 55px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index: 9;
  max-width: 1400px;

  margin: auto;
  svg {
    cursor: pointer;
    max-width: 1690px;
    max-height: 320px;
  }
`;

const HeaderLimit = styled.div`
  width: 100%;
  background: white;
  position: fixed;
  ${(props) => (props.closedSticky == false ? `top: 41px;` : `top: 0;`)}
  z-index: 1000;
  border-bottom: 1px solid #e1e1e1;
`;

const NavBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-transform: Uppercase;

  a {
    font-size: 12px;
    line-height: 28px;
    text-decoration: none;
    color: #000000;
    margin-left: 5%;
    display: flex;
    text-transform: Uppercase;
  }
  width: 100%;
`;

const NavBarMobile = styled.div`
  display: none;
  justify-content: flex-end;
  align-items: center;
  text-transform: capitalize;
  a {
    font-size: 12px;
    line-height: 28px;
    text-decoration: none;
    color: #000000;
    margin-left: 5%;
    display: flex;
    text-transform: Uppercase;

    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: ${(props) => (props.open ? `32px 0;` : `0`)};
  }
`;

const Bread = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  svg {
    margin-top: 14px;
  }
  @media (max-width: 768px) {
    margin-top: 21px;
    display: flex;
  }
`;

const Download = styled.div`
  border: 1px solid rgb(219, 70, 42);
  padding: 0 16px;
  font-size: 12px;
  border-radius: 4px;
  margin-left: 5%;
  color: rgb(219, 70, 42);
  cursor: pointer;
  :hover {
    background: rgb(219, 70, 42);
    color: white;
  }

  @media (max-width: 768px) {
    margin-top: 24px;
  }
`;

function HeaderCheckout({ wrapLocation }) {
  const [open, setOpen] = useState(false);
  const [param, setParam] = useState("");

  /// Detects URL change
  const [loc, setLoc] = useState(null);
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action == "PUSH" || action == "REPLACE" || action == "POP")
        setLoc((loc) => (loc = !loc));
    });
  }, [setLoc]);

  useEffect(() => {
    setParam(window.location.search);
  }, [loc]);
  console.log(wrapLocation.pathname.split("/")[1]);
  const realRoute = wrapLocation?.pathname.split("/")[1];
  const route = realRoute
    ? realRoute.includes("lp")
      ? `/${realRoute}`
      : ""
    : "";

  //
  return (
    <BackgroundFiller>
      <HeaderLimit>
        <HeaderS>
          <Logo
            onClick={() => {
              navigate(`${route}/${param}`);
            }}
          />
          <NavBar>
            <CountryDropdown />
          </NavBar>
        </HeaderS>
      </HeaderLimit>
    </BackgroundFiller>
  );
}

export default HeaderCheckout;
