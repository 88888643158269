import { getCountry } from "./Geolocator";
import { FastSpringPriceFiltered } from "../utils/FastSpringPricesFiltered";

function determineLocalCurrencyBasedOnAllowedCurrencies(productPath) {
  if (productPath) {
    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var countryTag = getCountry(timezone)?.countryTag;

    var filteredProduct =
      FastSpringPriceFiltered[productPath].pricing[countryTag];

    if (filteredProduct?.currency?.toLowerCase() === "usd") {
      return "$";
    } else if (filteredProduct?.currency?.toLowerCase() === "aud") {
      return "$";
    } else if (filteredProduct?.currency?.toLowerCase() === "cad") {
      return "$";
    } else if (filteredProduct?.currency?.toLowerCase() === "eur") {
      return "€";
    } else if (filteredProduct?.currency?.toLowerCase() === "gbp") {
      return "£";
    }

    return "Currency not supported";
  }
}

export const productInfo = (path, amount, addOn) => {
  console.log("Path info: ", path);
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let countryTag = getCountry(timezone)?.countryTag;

  let isAddOn = addOn;

  let AddOnPrice = FastSpringPriceFiltered["eds"]?.pricing[countryTag]?.price;
  let productPrice = FastSpringPriceFiltered[path]?.pricing[countryTag]?.price;

  let currency = determineLocalCurrencyBasedOnAllowedCurrencies(path);

  let Product = FastSpringPriceFiltered[path]?.pricing[countryTag];

  console.log(
    "Is add on: ",
    addOn,
    path,
    isAddOn
      ? currency + (Product?.price * amount + AddOnPrice).toFixed(2).toString()
      : currency + (Product?.price * amount).toFixed(2).toString()
  );
  Product = {
    ...Product,
    priceFormatted: currency + Product?.price.toString(),
    totalPriceFormatter: isAddOn
      ? currency + (Product?.price * amount + AddOnPrice).toFixed(2).toString()
      : currency + (Product?.price * amount).toFixed(2).toString(),
    addOnPrice: currency + AddOnPrice?.toFixed(2),
    productPrice: currency + productPrice,
  };

  return Product;
};

export function countryInfo() {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let countryTag = getCountry(timezone)?.countryTag;
  let countryName = getCountry(timezone)?.countryName;

  let product = "pdf-reader";
  let filteredProduct = FastSpringPriceFiltered[product]?.pricing[countryTag];

  return {
    countryTag: countryTag,
    countryName: countryName,
    currency: filteredProduct?.currency,
  };
}
