exports.linkResolver = doc => {
  // URL for a page type
  if (doc.type === "job") {
    return `careers/${doc.uid}`
  }

  if (doc.type === "pdfpro_post") {
    return `/preview/blog/${doc.data?.category}/${
      doc.data?.url ? doc.data.url.replaceAll(" ", "-") : doc.uid
    }`
  }

  // Backup for all other types
  return `/${doc.uid}`
}
